import { useEffect, useRef } from "react";
import type { VirtuosoHandle } from "react-virtuoso";

import { type OpenShift, type TimeSlot } from "../types";
import { getItemTimeSlot } from "./buildVirtualShiftListGroup";
import type { VirtualShiftListGroup, VirtualShiftListItem } from "./types";

export function useScrollVirtualShiftList(
  shiftListGroupByDate: Map<string, VirtualShiftListGroup>,
  virtualShiftListItems: VirtualShiftListItem[]
) {
  const listRef = useRef<VirtuosoHandle>(null);

  const scrollToDate = (dateIso: string) => {
    const dateGroupIndex = virtualShiftListItems.findIndex(
      (item) => item.type === "group-header" && item.dateIso === dateIso
    );

    if (dateGroupIndex >= 0) {
      listRef.current?.scrollToIndex({ index: dateGroupIndex });
    }
  };

  const findFirstTimeSlotShiftIndex = (dateIso: string, timeSlot: TimeSlot) => {
    const group = shiftListGroupByDate.get(dateIso);
    const firstTimeSlotShift = group?.shifts.find(
      (shift) => shift.type === "open-shift" && getItemTimeSlot(shift) === timeSlot
    ) as OpenShift | undefined;

    return virtualShiftListItems.findIndex(
      (shift) => shift.type === "open-shift" && shift.id === firstTimeSlotShift?.id
    );
  };

  // Used to scroll when user clicks on a time slot in the group header
  // and the time slot shifts are not visible in the list yet because
  // they were filtered out by the time slot filter. After they are
  // visible, the scroll will happen automatically.
  const pendingScrollToTimeSlot = useRef<{ dateIso: string; timeSlot: TimeSlot } | undefined>();
  let pendingScrollToTimeSlotIndex = -1;
  if (pendingScrollToTimeSlot.current) {
    pendingScrollToTimeSlotIndex = findFirstTimeSlotShiftIndex(
      pendingScrollToTimeSlot.current.dateIso,
      pendingScrollToTimeSlot.current.timeSlot
    );
  }

  useEffect(() => {
    if (pendingScrollToTimeSlotIndex >= 0) {
      pendingScrollToTimeSlot.current = undefined;
      listRef.current?.scrollToIndex({ index: pendingScrollToTimeSlotIndex });
    }
  }, [pendingScrollToTimeSlotIndex]);

  const scrollToTimeSlotInGroup = (dateIso: string, timeSlot: TimeSlot) => {
    const firstTimeSlotShiftIndex = findFirstTimeSlotShiftIndex(dateIso, timeSlot);
    if (firstTimeSlotShiftIndex >= 0) {
      listRef.current?.scrollToIndex({ index: firstTimeSlotShiftIndex });
    } else {
      pendingScrollToTimeSlot.current = { dateIso, timeSlot };
    }
  };

  return {
    listRef,
    scrollToDate,
    scrollToTimeSlotInGroup,
  };
}
