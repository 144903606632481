import { Title } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";

import { ShiftsByTimeSlotButtons } from "./ShiftsByTimeSlotButtons";
import { type ShiftsByTimeSlotBreakdown, type TimeSlot } from "./types";

interface ShiftListDateHeaderProps {
  label: string;
  showDivider?: boolean;
  timeSlotFilters?: TimeSlot[];
  totalShiftsCount: number;
  shiftsByTimeSlotBreakdown: ShiftsByTimeSlotBreakdown;
  onTimeSlotClick: (timeSlot: TimeSlot) => void;
}

export function ShiftListDateHeader(props: ShiftListDateHeaderProps) {
  const {
    label,
    showDivider,
    timeSlotFilters = [],
    shiftsByTimeSlotBreakdown,
    onTimeSlotClick,
    totalShiftsCount,
  } = props;

  const visibleShiftsCount = Object.entries(shiftsByTimeSlotBreakdown).reduce(
    (sum, [timeSlot, count]) => {
      if (timeSlotFilters.length === 0 || timeSlotFilters.includes(timeSlot as TimeSlot)) {
        return sum + count;
      }

      return sum;
    },
    0
  );

  return (
    <Box
      aria-label={label}
      sx={(theme) => ({
        paddingLeft: 3,
        paddingBottom: visibleShiftsCount > 0 ? 4 : 0,
        ...(showDivider
          ? {
              paddingTop: 6,
              marginTop: 6,
              borderTop: `1px solid ${String(theme.palette.border!.primary)}`,
            }
          : {}),
      })}
    >
      <Stack alignItems="flex-start" spacing={4}>
        <Title
          semibold
          variant="h5"
          component="h2"
          sx={(theme) => ({
            paddingTop: 4,
            color:
              totalShiftsCount === 0 ? theme.palette.text.disabled : theme.palette.text.primary,
          })}
        >
          {label}
        </Title>

        {totalShiftsCount > 0 && (
          <ShiftsByTimeSlotButtons
            shiftsByTimeSlotBreakdown={shiftsByTimeSlotBreakdown}
            timeSlotFilters={timeSlotFilters}
            onSlotClick={onTimeSlotClick}
          />
        )}
      </Stack>
    </Box>
  );
}
