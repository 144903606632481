import { Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib";

import { DistanceText } from "../../components/DistanceText";
import { DotSeparatedList } from "../../components/DotSeparatedList";
import { DateWithDuration } from "../../DateTime/DateWithDuration";
import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { WorkplaceNameWithPin } from "./WorkplaceNameWithPin";

interface ShiftSummaryInfoProps {
  dateRange: DateRange;
  deviceTimezone: string;
  distanceIsError: boolean;
  distanceIsLoading: boolean;
  durationInHours: number;
  workplaceName: string;
  formattedDistance?: string;
  timezone?: string;
}

/**
 * Renders summary about the open shift
 * - Time range
 * - Workplace name and distance
 * - Duration
 */
export function ShiftCardSummaryInfo(props: ShiftSummaryInfoProps) {
  const {
    dateRange,
    timezone,
    deviceTimezone,
    durationInHours,
    workplaceName,
    formattedDistance,
    distanceIsError,
    distanceIsLoading,
  } = props;

  return (
    <Stack sx={{ marginBottom: 3 }}>
      <TimeRangeLabel
        semibold
        dateRange={dateRange}
        timezone={timezone}
        deviceTimezone={deviceTimezone}
        variant="h6"
      />

      <DateWithDuration
        dateRange={dateRange}
        durationInHours={durationInHours}
        timezone={timezone}
        variant="body2"
        sx={{ marginTop: 2 }}
      />

      <DotSeparatedList inline={false} sx={{ marginTop: 1 }}>
        <WorkplaceNameWithPin workplaceName={workplaceName} />

        {formattedDistance && (
          <DistanceText
            isError={distanceIsError}
            isLoading={distanceIsLoading}
            formattedDistance={formattedDistance}
            variant="body2"
            sx={{
              flexShrink: 0,
            }}
          />
        )}
      </DotSeparatedList>
    </Stack>
  );
}
