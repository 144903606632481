import { isEqual, uniq } from "lodash";
import { useEffect, useRef, useState } from "react";

import { type TimeSlot } from "../types";

export function useVisibleTimeSlotsByDate(timeSlotFilters: TimeSlot[]) {
  const [visibleTimeSlotsByDateMap, setVisibleTimeSlotsByDateMap] = useState<
    Map<string, TimeSlot[]>
  >(new Map());

  // Reset the visible time slots when the time slot filters change
  const latestTimeSlotFilters = useRef(timeSlotFilters);
  useEffect(() => {
    if (!isEqual(latestTimeSlotFilters.current, timeSlotFilters)) {
      latestTimeSlotFilters.current = timeSlotFilters;
      setVisibleTimeSlotsByDateMap(new Map());
    }
  }, [timeSlotFilters, visibleTimeSlotsByDateMap]);

  const onVisibleTimeSlotsChange = (dateIso: string, timeSlots: TimeSlot[]) => {
    setVisibleTimeSlotsByDateMap((previousTimeSlotsByDate) => {
      const newMap = new Map(previousTimeSlotsByDate);
      const existingTimeSlots = newMap.get(dateIso) ?? timeSlotFilters;
      newMap.set(dateIso, uniq([...existingTimeSlots, ...timeSlots]));
      return newMap;
    });
  };

  return {
    visibleTimeSlotsByDateMap,
    onVisibleTimeSlotsChange,
  };
}
