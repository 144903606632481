import {
  type CreateOfferResponse,
  createOfferResponse,
  createOfferSchema,
} from "@clipboard-health/contract-worker-app-bff";
import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import {
  type QueryClient,
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { parseISO } from "date-fns";

export const CREATE_OFFER_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/offers`;

interface UseCreateOfferParams {
  shiftId: string;
}

/**
 * Caches the offer in the query client based on the offer's expiration time so we're
 * not constantly making requests while the user scrolls up and down.
 */
function cacheOffer(queryClient: QueryClient, shiftId: string, offer: CreateOfferResponse) {
  const expiresAtToMs = parseISO(offer.data.attributes.refreshAt).getTime();
  const timeRemaining = expiresAtToMs - Date.now();

  queryClient.setQueryData(["offer", shiftId], () => offer);
  queryClient.setQueryDefaults(["offer", shiftId], {
    staleTime: timeRemaining,
  });
}

export function useCreateOffer(
  options: UseMutationOptions<
    CreateOfferResponse,
    AxiosError<CreateOfferResponse>,
    UseCreateOfferParams
  > = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    retry: (failureCount, error) => {
      // Don't retry if forbidden, means we can't create an offer
      if (error.response?.status === 403) {
        return false;
      }

      return failureCount < 3;
    },
    retryDelay: 300,
    mutationFn: async (data) => {
      const existingOffer = queryClient.getQueryData<CreateOfferResponse>(["offer", data.shiftId]);
      if (existingOffer) {
        return existingOffer;
      }

      const response = await post({
        url: CREATE_OFFER_URL,
        data: {
          data: {
            type: "offer",
            attributes: {},
            relationships: {
              shift: {
                data: {
                  type: "open-shift",
                  id: data.shiftId,
                },
              },
            },
          },
        },
        requestSchema: createOfferSchema,
        responseSchema: createOfferResponse,
      });

      return response.data;
    },
    onSuccess: (newOffer, params) => {
      cacheOffer(queryClient, params.shiftId, newOffer);
    },
    ...options,
  });
}
