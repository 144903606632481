import { type TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { CardActionArea, CardContent, type SxProps, type Theme } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { type GeoLocation } from "@src/appV2/Location";
import { differenceInHours, parseISO } from "date-fns";
import { generatePath, useHistory } from "react-router-dom";

import { useDistanceToWorkplace } from "../../Workplace/useDistanceToWorkplace";
import { ShiftCardWrapper } from "../Card/Wrapper";
import { type ShiftType } from "../constants";
import { ShiftCardPillsWrapper } from "../PillsWrapper";
import { ShiftQualificationPill } from "../QualificationPill";
import { BookedShiftPill } from "../WorkerShift/BookedPill";
import { ShiftCardSummaryInfo } from "./CardSummaryInfo";
import { ShiftCardHeader } from "./ShiftCardHeader";

interface OpenShiftsListWorkerShiftCardProps {
  workplaceName: string;
  workplaceTimezone?: string;
  workplaceGeoLocation: GeoLocation;

  shiftId: string;
  shiftStartIso: string;
  shiftEndIso: string;
  shiftType: ShiftType;
  shiftFinalPay?: number;
  shiftOriginalAmount?: number;
  shiftOfferPay?: number;
  shiftTimeSlot: TimeSlot;

  qualificationName: string;
  sx?: SxProps<Theme>;
}

export function OpenShiftsListWorkerShiftCard(props: OpenShiftsListWorkerShiftCardProps) {
  const {
    sx,
    workplaceName,
    workplaceTimezone,
    workplaceGeoLocation,
    shiftId,
    shiftStartIso,
    shiftEndIso,
    shiftType,
    shiftFinalPay,
    shiftOriginalAmount,
    shiftOfferPay,
    shiftTimeSlot,
    qualificationName,
  } = props;

  const dateRange = { startDate: parseISO(shiftStartIso), endDate: parseISO(shiftEndIso) };
  const history = useHistory();

  const {
    formattedDistance,
    isLoading: distanceIsLoading,
    isError: distanceIsError,
  } = useDistanceToWorkplace({
    workplaceGeoLocation,
  });

  return (
    <ShiftCardWrapper shiftType={shiftType} sx={sx}>
      <CardActionArea
        sx={{ flexGrow: 1 }}
        onClick={() => {
          history.push(generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, { shiftId }));
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 3,
            height: "100%",
            alignItems: "stretch",
          }}
        >
          <ShiftCardHeader
            isBooked
            timeSlot={shiftTimeSlot}
            offerPay={shiftOfferPay}
            finalPay={shiftFinalPay}
            originalAmount={shiftOriginalAmount}
          />

          <ShiftCardSummaryInfo
            dateRange={dateRange}
            timezone={workplaceTimezone}
            deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
            durationInHours={differenceInHours(dateRange.endDate, dateRange.startDate)}
            workplaceName={workplaceName}
            distanceIsError={distanceIsError}
            distanceIsLoading={distanceIsLoading}
            formattedDistance={formattedDistance}
          />

          <ShiftCardPillsWrapper>
            <ShiftQualificationPill qualificationName={qualificationName} />
            <BookedShiftPill />
          </ShiftCardPillsWrapper>
        </CardContent>
      </CardActionArea>
    </ShiftCardWrapper>
  );
}
