import { isDefined } from "@clipboard-health/util-ts";
import { formatISO, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { groupBy } from "lodash";

import { type OpenShiftListItem, type OpenShiftWorkplace, type TimeSlot } from "../types";
import { buildVirtualShiftListGroup } from "./buildVirtualShiftListGroup";
import type { VirtualShiftListGroup } from "./types";

export const getVirtualShiftListItemDateIso = (
  item: OpenShiftListItem,
  timezone?: string
): string => {
  const dateString = "start" in item ? item.start : item.attributes.start;
  const date = parseISO(dateString);

  if (isDefined(timezone)) {
    return formatInTimeZone(date, timezone, "yyyy-MM-dd");
  }

  return formatISO(date, { representation: "date" }); // yyyy-MM-dd
};

export function getVirtualShiftListGroupsMap({
  shifts,
  workplacesMap,
  timeSlotsForDate,
}: {
  shifts: OpenShiftListItem[];
  workplacesMap: Map<string, OpenShiftWorkplace>;
  timeSlotsForDate: (dateIso: string) => TimeSlot[];
}) {
  const shiftListGroupByDate = new Map<string, VirtualShiftListGroup>();

  const shiftsByDate = groupBy(shifts, (shift) => {
    if (shift.type !== "open-shift") {
      return getVirtualShiftListItemDateIso(shift, shift.facility.tmz);
    }

    const workplace = workplacesMap.get(shift.relationships.workplace.data.id);
    return getVirtualShiftListItemDateIso(shift, workplace?.attributes.location.timezone);
  });

  Object.entries(shiftsByDate).forEach(([dateIso, shiftsForDate]) => {
    const shiftGroup = buildVirtualShiftListGroup({
      dateIso,
      shiftsForDate,
      visibleTimeSlots: timeSlotsForDate(dateIso),
    });

    shiftListGroupByDate.set(dateIso, shiftGroup);
  });

  return shiftListGroupByDate;
}
