import { type OpenShiftListItem, type ShiftsByTimeSlotBreakdown, TimeSlot } from "../types";
import type { VirtualShiftListGroup, VirtualShiftListGroupHeader } from "./types";

export const getItemTimeSlot = (item: OpenShiftListItem): TimeSlot => {
  return item.type === "open-shift" ? item.attributes.timeSlot : item.name;
};

interface BuildVirtualShiftListGroupProps {
  dateIso: string;
  shiftsForDate: OpenShiftListItem[];
  visibleTimeSlots: TimeSlot[];
}

export function buildVirtualShiftListGroup(
  props: BuildVirtualShiftListGroupProps
): VirtualShiftListGroup {
  const { dateIso, shiftsForDate, visibleTimeSlots } = props;

  const shiftsByTimeSlotBreakdown: ShiftsByTimeSlotBreakdown = {
    [TimeSlot.AM]: 0,
    [TimeSlot.PM]: 0,
    [TimeSlot.NOC]: 0,
  };

  const groupHeader: VirtualShiftListGroupHeader = {
    type: "group-header",
    dateIso,
    totalShiftsCount: shiftsForDate.length,
    visibleTimeSlots,
    shiftsByTimeSlotBreakdown,
  };

  const group: VirtualShiftListGroup = {
    dateIso,
    shifts: shiftsForDate,
    listItems: [groupHeader],
  };

  // Worker shifts are always at the top of the list, independent of the time slot
  const workerShifts = shiftsForDate.filter((shift) => shift.type !== "open-shift");
  group.listItems.push(...workerShifts);

  const openShifts = shiftsForDate.filter((shift) => shift.type === "open-shift");
  openShifts.forEach((shift) => {
    const timeSlot = getItemTimeSlot(shift);
    shiftsByTimeSlotBreakdown[timeSlot] += 1;

    if (visibleTimeSlots.length > 0 && !visibleTimeSlots.includes(timeSlot)) {
      return;
    }

    group.listItems.push(shift);
  });

  return group;
}
