/* eslint-disable import/max-dependencies */
import { mergeSxProps } from "@clipboard-health/ui-react";
import { CardActionArea, CardContent, type SxProps, type Theme } from "@mui/material";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { type DateRange } from "@src/appV2/lib/Calendar";
import { isShiftPriorityValid } from "@src/appV2/lib/utils/isShiftPriorityValid";
import { USER_EVENTS } from "@src/constants";
import { differenceInMinutes, parseISO } from "date-fns";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { useDistanceToWorkplace } from "../../Workplace/useDistanceToWorkplace";
import { isShiftInstantReview } from "../Bookability/Documents/InstantReview/isShiftInstantReview";
import { ShiftCardWrapper } from "../Card/Wrapper";
import { ShiftCardPillsWrapper } from "../PillsWrapper";
import { ShiftQualificationPill } from "../QualificationPill";
import { resolveShiftType } from "../resolveShiftType";
import { ShiftCardSummaryInfo } from "./CardSummaryInfo";
import { OpenShiftCardPills } from "./Pills";
import { ShiftCardHeader } from "./ShiftCardHeader";
import type { Offer, OpenShift, OpenShiftWorkplace } from "./types";
import { useCreateOffer } from "./useCreateOffer";

interface OpenShiftCardProps {
  shift: OpenShift;
  workplace: OpenShiftWorkplace;
  onClick?: (shift: OpenShift, workplace: OpenShiftWorkplace, offer?: Offer) => void;
  missingDocuments: HcpRequirement[];
  pendingDocuments: HcpRequirement[];
  sx?: SxProps<Theme>;
}

export function OpenShiftCard(props: OpenShiftCardProps) {
  const { shift, workplace, onClick, missingDocuments, pendingDocuments, sx } = props;
  const { timeSlot, start, end, qualification, window, durationInHours, priorityTill, isUrgent } =
    shift.attributes;

  const dateRange: DateRange = { startDate: parseISO(start), endDate: parseISO(end) };
  const shiftType = resolveShiftType({ isUrgent, window, priorityTill });

  const isHelloSignEmbeddedFlowEnabled = useCbhFlag(
    CbhFeatureFlag.HELLO_SIGN_EMBEDDED_FLOW_INTEGRATION,
    {
      defaultValue: false,
    }
  );

  const {
    mutate: createOffer,
    isLoading: offerIsLoading,
    isError: offerIsError,
    data: { data: offer } = {},
  } = useCreateOffer();

  useEffect(() => {
    if (!offer) {
      createOffer({ shiftId: shift.id });
    }
  }, [offer, createOffer, shift.id]);

  const { ref } = useInView({
    rootMargin: "0px",
    threshold: 0.2,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        logEvent(USER_EVENTS.OPEN_SHIFT_VIEWED, {
          start,
          end,
          facility_id: shift.relationships.workplace.data.id,
          shift_id: shift.id,
          hourly_pay: offer ? offer.attributes.pay.amountInMinorUnits / 100 : 0,
          agent_req: qualification,
          shift_type: shiftType,
          has_missing_documents: missingDocuments.length > 0,
          isPriorityAccessShift: priorityTill ? isShiftPriorityValid(priorityTill) : false,
          window,
          priorityAccessTimeRemaining: priorityTill
            ? differenceInMinutes(Date.now(), parseISO(priorityTill))
            : 0,
          shiftOfferId: offer ? offer.id : undefined,
          is_instant_review: isShiftInstantReview({
            isHelloSignEmbeddedFlowEnabled,
            missingDocuments,
          }),
          missing_documents: missingDocuments.map((document) => document.reqId),
        });
      }
    },
  });

  const {
    formattedDistance,
    isLoading: distanceIsLoading,
    isError: distanceIsError,
  } = useDistanceToWorkplace({
    workplaceGeoLocation: workplace.attributes.location,
  });

  const offerAmount = offer ? offer.attributes.pay.amountInMinorUnits / 100 : 0;

  return (
    <ShiftCardWrapper
      shiftType={shiftType}
      sx={mergeSxProps({ display: "flex", opacity: offerIsError ? 0.5 : 1 }, sx)}
      data-testid={`shift-card-${shift.id}`}
    >
      <CardActionArea
        ref={ref}
        sx={{ flexGrow: 1 }}
        onClick={() => onClick?.(shift, workplace, offer)}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 3,
            height: "100%",
            alignItems: "stretch",
          }}
        >
          <ShiftCardHeader
            offerUnavailable={offerIsError}
            timeSlot={timeSlot}
            isLoading={offerIsLoading}
            offerPay={offerAmount}
            finalPay={offerAmount}
            originalAmount={durationInHours * offerAmount}
          />

          <ShiftCardSummaryInfo
            dateRange={dateRange}
            timezone={workplace.attributes.location.timezone}
            deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
            durationInHours={durationInHours}
            workplaceName={workplace.attributes.name}
            distanceIsError={distanceIsError}
            distanceIsLoading={distanceIsLoading}
            formattedDistance={formattedDistance}
          />

          <ShiftCardPillsWrapper>
            <ShiftQualificationPill qualificationName={qualification} />

            <OpenShiftCardPills
              shift={shift}
              shiftType={shiftType}
              missingDocuments={missingDocuments}
              pendingDocuments={pendingDocuments}
            />
          </ShiftCardPillsWrapper>
        </CardContent>
      </CardActionArea>
    </ShiftCardWrapper>
  );
}
/* eslint-enable import/max-dependencies */
