import { Text, type TextProps } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { CbhIcon } from "../../components/CbhIcon";

interface WorkplaceNameWithPinProps {
  workplaceName: string;
  textVariant?: TextProps["variant"];
}

/**
 * Renders a workplace name with a map pin icon.
 */
export function WorkplaceNameWithPin(props: WorkplaceNameWithPinProps) {
  const { workplaceName, textVariant = "body2" } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CbhIcon type="map-pin" size="xSmall" />

      <Text
        variant={textVariant}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {workplaceName}
      </Text>
    </Stack>
  );
}
