import constate from "constate";

import { type OpenShiftListItem, type OpenShiftWorkplace, type TimeSlot } from "../types";
import type { VirtualShiftListGroupHeader, VirtualShiftListItem } from "./types";
import { useScrollVirtualShiftList } from "./useScrollVirtualShiftList";
import { useVisibleTimeSlotsByDate } from "./useVisibleTimeSlotsByDate";
import { getVirtualShiftListGroupsMap } from "./utils";

interface UseVirtualShiftListProps {
  shifts: OpenShiftListItem[];
  timeSlotFilters?: TimeSlot[];
  workplacesMap: Map<string, OpenShiftWorkplace>;
}

export function useVirtualShiftList(props: UseVirtualShiftListProps) {
  const { timeSlotFilters = [], shifts, workplacesMap } = props;

  const { visibleTimeSlotsByDateMap, onVisibleTimeSlotsChange } =
    useVisibleTimeSlotsByDate(timeSlotFilters);

  const shiftListGroupByDate = getVirtualShiftListGroupsMap({
    shifts,
    workplacesMap,
    timeSlotsForDate: (dateIso) =>
      visibleTimeSlotsByDateMap.has(dateIso)
        ? visibleTimeSlotsByDateMap.get(dateIso)!
        : timeSlotFilters,
  });

  const virtualShiftListItems: VirtualShiftListItem[] = [...shiftListGroupByDate.values()].flatMap(
    (group) => group.listItems
  );

  const visibleOpenShiftsCount = virtualShiftListItems.filter(
    (item) => item.type === "open-shift"
  ).length;

  const { listRef, scrollToDate, scrollToTimeSlotInGroup } = useScrollVirtualShiftList(
    shiftListGroupByDate,
    virtualShiftListItems
  );

  const onGroupHeaderTimeSlotClick = (
    groupHeader: VirtualShiftListGroupHeader,
    timeSlot: TimeSlot
  ) => {
    if (
      groupHeader.visibleTimeSlots.length > 0 &&
      !groupHeader.visibleTimeSlots.includes(timeSlot)
    ) {
      onVisibleTimeSlotsChange(groupHeader.dateIso, [timeSlot]);
    }

    scrollToTimeSlotInGroup(groupHeader.dateIso, timeSlot);
  };

  return {
    listRef,
    virtualShiftListItems,
    visibleOpenShiftsCount,
    scrollToDate,
    onGroupHeaderTimeSlotClick,
  };
}

export const [VirtualShiftListContextProvider, useVirtualShiftListContext] =
  constate(useVirtualShiftList);
