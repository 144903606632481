import { Stack } from "@mui/material";

import { Pill } from "../../components/Pill";
import { ShiftCardPayInfoCard } from "../Card/PayInfoCard";
import { TimeSlotIndicator } from "../TimeSlotIndicator";
import { type TimeSlot } from "./types";

interface ShiftCardHeaderProps {
  timeSlot: TimeSlot;
  isLoading?: boolean;
  offerUnavailable?: boolean;
  offerPay?: number;
  finalPay?: number;
  originalAmount?: number;
  isBooked?: boolean;
}

/**
 * Renders row with time slot indicator and pay info card
 */
export function ShiftCardHeader(props: ShiftCardHeaderProps) {
  const { timeSlot, isLoading, offerPay, finalPay, originalAmount, isBooked, offerUnavailable } =
    props;

  return (
    <Stack
      direction="row"
      sx={{ alignItems: "flex-start", justifyContent: "space-between", flexGrow: 1 }}
      spacing={5}
    >
      <TimeSlotIndicator
        timeSlot={timeSlot}
        sx={{
          background: isBooked ? (theme) => theme.palette.intent?.success.background : undefined,
          color: isBooked ? (theme) => theme.palette.intent?.success.icon : undefined,
        }}
      />

      {offerUnavailable ? (
        <Pill
          label="Shift is unavailable"
          color={(theme) => theme.palette.text.secondary}
          iconType="info"
        />
      ) : (
        <ShiftCardPayInfoCard
          isLoading={isLoading}
          offerPay={offerPay}
          finalPay={finalPay}
          originalAmount={originalAmount}
        />
      )}
    </Stack>
  );
}
