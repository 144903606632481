import { Stack } from "@mui/material";
import { type ItemProps } from "react-virtuoso";

import type { VirtualShiftListItem } from "./useVirtualShiftListContext/types";

/**
 * A wrapper for the item component for react-virtuoso's list.
 * Adds a bottom padding after each item instead of margin because
 * Virtuoso recommends not using margins to avoid miscalculations
 * in their content measuring mechanism: https://virtuoso.dev/#caveats
 */
export function ShiftListItemWrapper(props: ItemProps<VirtualShiftListItem>) {
  // No need to pass `item` to the dom element
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { item: _, ...rest } = props;
  return <Stack {...rest} pt={5} />;
}
