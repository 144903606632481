import { ButtonBase, Stack } from "@mui/material";

import { CbhIcon } from "../../components/CbhIcon";
import { TimeSlotShiftsCount } from "../TimeSlotShiftsCount";
import { type ShiftsByTimeSlotBreakdown, type TimeSlot } from "./types";

interface ShiftsByTimeSlotProps {
  shiftsByTimeSlotBreakdown: ShiftsByTimeSlotBreakdown;
  /**
   * TimeSlots to be shown. If this is empty, all time slots will be shown.
   */
  timeSlotFilters: TimeSlot[];
  onSlotClick: (timeSlot: TimeSlot) => void;
}

export function ShiftsByTimeSlotButtons(props: ShiftsByTimeSlotProps) {
  const { shiftsByTimeSlotBreakdown, timeSlotFilters, onSlotClick } = props;

  const timeSlots: TimeSlot[] = Object.keys(shiftsByTimeSlotBreakdown) as TimeSlot[];

  return (
    <Stack direction="row" gap={5}>
      {timeSlots.map((timeSlot) => {
        const isFilteredOut = timeSlotFilters.length > 0 && !timeSlotFilters.includes(timeSlot);
        const isEmpty = shiftsByTimeSlotBreakdown[timeSlot] === 0;

        if (isEmpty) {
          return null;
        }

        return (
          <ButtonBase
            key={timeSlot}
            sx={{ py: 1, gap: 2 }}
            onClick={() => {
              onSlotClick(timeSlot);
            }}
          >
            <TimeSlotShiftsCount
              isInactive={isFilteredOut}
              timeSlot={timeSlot}
              shiftsCount={shiftsByTimeSlotBreakdown[timeSlot]}
            />

            {isFilteredOut && (
              <CbhIcon
                type="chevron-down"
                size="xSmall"
                color={(theme) => theme.palette.text.tertiary}
              />
            )}
          </ButtonBase>
        );
      })}
    </Stack>
  );
}
